@mixin responsive($breakpoint) {
  @if $breakpoint == phone {
    @media only screen and (max-width: 37.5em) {
      // 600px --> 600 / 16
      @content;
    }
  }

  @if $breakpoint == tab_medium {
    // 980px
    @media only screen and (max-width: 61.25em) {
      @content;
    }
  }

  @if $breakpoint == tab_port {
    @media only screen and (max-width: 74em) {
      // 1184px
      @content;
    }
  }

  @if $breakpoint == lg_desktop {
    @media only screen and (max-width: 102em) {
      // 1633px
      @content;
    }
  }

  @if $breakpoint == phone_landscape {
    @media screen and (min-device-width: 25.87em) 
      and (max-device-width: 56.25em) and (orientation: landscape) {
        //min 414 - max 736px
        @content;
      }
  }
}