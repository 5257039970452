@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
@import "./functions.scss";
* {
  font-family: "Roboto", sans-serif;
}

.side-a {
  padding: 0;
  background: yellow;
  .photoHolder {
    display: block;
    position: relative;
    width: 100%;
    height: auto;
    img {
      width: 100%;
      object-fit: contain;
    }
  }

  .firstText,
  .secondText {
    position: absolute;
    bottom: 2.5em;
    right: 0.3em;
    color: white;
    font-size: 3em;
    background: navy;
    padding: 0.3em;
   @include responsive(phone) {
      font-size: 2em;
    }
  }
   

  .secondText {
    bottom: 0.5em;
    color: navy;
    background: white;
  }
}

.callNow {
  background: yellow;
  padding: 1em 0em;
  font-size: 2em;
  text-transform: uppercase;
  @include responsive(phone) {
      font-size: 1em;
    }
}

.consulenza {
  font-size: 1.5em;
  font-weight: 500;
  @include responsive(phone) {
      font-size: 1.1em;
    }
}

.form-control {
  width: 100% !important;
  height: 1.6em !important;
  font-size: 2em !important;
}

.callGreen {
  background: green;
  padding: 1em 0em;
  font-size: 2em;
  text-transform: uppercase;
  color: white;
  margin: 0;
  padding: 0;
  width: 100%;
  @include responsive(phone) {
      font-size: 1.6em;
    }
}

.disabled {
  opacity: 0.5;
}

.form-check {
  label {
    text-align: left;
    text-align: justify;
    @include responsive(phone) {
      font-size: 0.8em;
    }
  }
}
